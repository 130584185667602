import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const Context = createContext();

function Provider({ children }) {
  const [allData, setAllData] = useState([]);

  const getDataFromAPI = () => {
    axios.get("https://yasinakbulut.com/ev/datas").then(function (response) {
      setAllData(JSON.parse(response.data));
    });
  };

  useEffect(() => {
    getDataFromAPI();
  }, []);

  const sharedValuesAndMethods = {
    allData,
    setAllData,
    getDataFromAPI,
  };

  console.log("context");

  return (
    <Context.Provider value={sharedValuesAndMethods}>
      {children}
    </Context.Provider>
  );
}
const useHomeContext = () => useContext(Context);
export { Provider, useHomeContext };
export default Context;
