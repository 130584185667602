import React from "react";

function TableItem({ data, index, updateData, deleteData }) {

  let toplamOdenen = 0;

  data.odemeTaksitleri.map((odeme) => (toplamOdenen = toplamOdenen + odeme));

  return (
    <tr>
      <td width={40}>{index + 1}</td>
      <td width={300}>{data.is}</td>
      <td width={100}>{data.fiyat.toLocaleString("de-DE")}</td>
      <td width={100} className="align-top">
        {data.odemeTaksitleri.map((odeme, key) => (
          <span key={key}>{odeme.toLocaleString("de-DE")}</span>
        ))}
      </td>
      <td width={100}>
        <span>{(data.fiyat - toplamOdenen).toLocaleString("de-DE")}</span>
      </td>
      <td width={120}>
        <span
          className={`badge bg-${data.durum[0]}-subtle border border-${data.durum[0]}-subtle text-${data.durum[0]}-emphasis fw-normal rounded-pill`}
        >
          {data.durum[1]}
        </span>
      </td>
      <td width={200}  className="hide-print">
        <div className="d-inline-flex flex-wrap gap-2">
          <button
            onClick={() => updateData(index)}
            type="button"
            className={`badge bg-info-subtle border border-info-subtle text-info-emphasis fw-normal rounded-pill`}
          >
            Düzenle
          </button>
          <button
            onClick={() => deleteData(index)}
            type="button"
            className={`badge bg-danger-subtle border border-danger-subtle text-danger-emphasis fw-normal rounded-pill`}
          >
            Sil
          </button>
        </div>
      </td>
    </tr>
  );
}

export default TableItem;
