import React, { useState } from "react";
import { useHomeContext } from "./Context";
import TableItem from "./TableItem";
import axios from "axios";
function App() {
  const { allData ,setAllData} = useHomeContext();

  let genelTutar = 0,
    genelYaklasik = 0,
    genelVerilen = 0;

  allData.map((data) => (genelTutar += data.fiyat));
  allData.forEach((data) => {
    if (data.durum[1] === "yaklaşık") {
      genelYaklasik += data.fiyat;
    }
    data.odemeTaksitleri.map((odeme) => (genelVerilen += odeme));
  });

  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditIndex, setIsEditIndex] = useState();

  const [isInput, setIsInput] = useState("");
  const [tutarInput, setTutarInput] = useState("");
  const [verilenlerInput, setVerilenlerInput] = useState("");
  const [durumInput, setDurumInput] = useState("");

  const setData = (data) => setAllData(data)

  const axiosApi = async (data) => {
    await setData(data)
    axios
      .post("https://yasinakbulut.com/ev/data/save", data)
      .then(function (response) {
        if (response.status === 200) {
          setSuccessMsg((prev) => !prev);
          setTimeout(() => {
            setSuccessMsg((prev) => !prev);
          }, 3000);
        } else {
          setErrorMsg((prev) => !prev);
          setTimeout(() => {
            setErrorMsg((prev) => !prev);
          }, 3000);
        }
      });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (
      isInput === "" ||
      tutarInput === "" ||
      verilenlerInput === "" ||
      durumInput === ""
    ) {
      alert("Alanlar boş bırakılamaz.");
      return;
    }

    const odemeTaksitleri = verilenlerInput.split(",").map(Number);
    const durum = durumInput.split(",");

    let newData;

    if (isEdit) {
      allData[isEditIndex].is = isInput;
      allData[isEditIndex].fiyat = Number(tutarInput);
      allData[isEditIndex].odemeTaksitleri = odemeTaksitleri;
      allData[isEditIndex].durum = durum;

      newData = allData

    } else {
      newData = [
        ...allData,
        {
          is: isInput,
          fiyat: Number(tutarInput),
          odemeTaksitleri,
          durum,
        },
      ];
    }

    axiosApi(newData);

    setIsInput("");
    setTutarInput("");
    setVerilenlerInput("");
    setDurumInput("");

    setIsEdit(false);
  };

  const updateData = (index) => {
    setIsEdit(true);
    setIsEditIndex(index);
    setIsInput(allData[index].is);
    setTutarInput(allData[index].fiyat);
    setVerilenlerInput(allData[index].odemeTaksitleri.join(","));
    setDurumInput(allData[index].durum.join(","));
  };

  const deleteData = (index) => {
    const confirmed = window.confirm("Emin misiniz? İşlem geri alınamaz!!!!");
    if (confirmed) {
      allData.splice(index, 1);
      axiosApi(allData);
    }
  };

  return (
    <div className="container py-5">
      <form onSubmit={submitForm.bind(this)} className="form mb-4 hide-print">
        <div className="d-flex gap-2 mb-4 flex-wrap">
          <span className="badge bg-primary-subtle border border-primary-subtle text-primary-emphasis fw-normal rounded-pill">
            Primary
          </span>
          <span className="badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis fw-normal rounded-pill">
            Secondary
          </span>
          <span className="badge bg-success-subtle border border-success-subtle text-success-emphasis rfw-normal ounded-pill">
            Success
          </span>
          <span className="badge bg-danger-subtle border border-danger-subtle text-danger-emphasis fw-normal rounded-pill">
            Danger
          </span>
          <span className="badge bg-warning-subtle border border-warning-subtle text-warning-emphasis fw-normal rounded-pill">
            Warning
          </span>
          <span className="badge bg-info-subtle border border-info-subtle text-info-emphasis fw-normal rounded-pill">
            İnfo
          </span>
        </div>
        {successMsg ? (
          <div className="alert alert-success">
            İşlem başarılı şekilde gerçekleşti
          </div>
        ) : (
          ""
        )}
        {errorMsg ? (
          <div className="alert alert-danger">
            İşlem gerçekleştirilirken başarısız oldu
          </div>
        ) : (
          ""
        )}
        <div className="row g-3">
          <div className="col-md">
            <input
              type="text"
              className="form-control"
              placeholder="iş"
              id="is"
              value={isInput}
              onChange={(e) => setIsInput(e.target.value)}
            />
          </div>
          <div className="col-md">
            <input
              type="number"
              className="form-control"
              placeholder="tutar"
              id="tutar"
              value={tutarInput}
              onChange={(e) => setTutarInput(e.target.value)}
            />
          </div>
          <div className="col-md">
            <input
              type="text"
              className="form-control"
              placeholder="verilenler (12000,15000)"
              id="verilenler"
              value={verilenlerInput}
              onChange={(e) => setVerilenlerInput(e.target.value)}
            />
          </div>
          <div className="col-md">
            <input
              type="text"
              className="form-control"
              placeholder="durum (primary,mesaj)"
              id="durum"
              value={durumInput}
              onChange={(e) => setDurumInput(e.target.value)}
            />
          </div>
          <div className="col-md">
            <button type="submit" className="button btn btn-primary">
              {isEdit ? "Düzenle" : "Ekle"}
            </button>
          </div>
        </div>
      </form>

      <div className="table-responsive ">
        <table
          style={{ minWidth: "650px" }}
          className="table table-striped table-bordered table-hover align-middle text-center"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>İş</th>
              <th>Tutar</th>
              <th>Verilen</th>
              <th>Kalan</th>
              <th>Durum</th>
              <th className="hide-print">İşlem</th>
            </tr>
          </thead>
          <tbody id="table-body">
            {allData.map((data, index) => (
              <TableItem
                key={index}
                data={data}
                updateData={updateData}
                deleteData={deleteData}
                index={index}
              />
            ))}

            <tr className="fw-bold">
              <td>{allData.length + 1}</td>
              <td>GENEL</td>
              <td>{genelTutar.toLocaleString("de-DE")}</td>
              <td>{genelVerilen.toLocaleString("de-DE")}</td>
              <td>
                <span>
                  {(genelTutar - genelVerilen - genelYaklasik).toLocaleString(
                    "de-DE"
                  )}
                  <p className="m-0 mx-auto mb-1 d-block w-max badge bg-warning-subtle border border-warning-subtle text-warning-emphasis fw-normal rounded-pill">
                    {genelYaklasik.toLocaleString("de-DE")}
                  </p>
                  <p className="m-0 mx-auto d-block w-max badge bg-info-subtle border border-info-subtle text-info-emphasis fw-normal rounded-pill">
                    {(genelTutar - genelVerilen).toLocaleString("de-DE")}
                  </p>
                </span>
              </td>
              <td colSpan={2}>
                <span
                  className={`badge bg-warning-subtle border border-warning-subtle text-warning-emphasis fw-normal rounded-pill`}
                >
                  ödeniyor
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
